export const sectionValues = {
  // specific to email upsell modal
  addEmail: 'addEmail',
  verifyEmail: 'verifyEmail',
  // specific to phone upsell modal
  addPhone: 'addPhone',
  verifyPhone: 'verifyPhone',
  phoneAdded: 'phoneAdded',
  discoverabilityConsent: 'discoverabilityConsent'
};

export const originValues = {
  unset: 'unset',
  homepage: 'homepage'
};
